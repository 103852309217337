import * as React from "react"
import { SearchProvider } from "./src/context/Search"

export const onRouteUpdate = () => {
  document.querySelectorAll("script").forEach(s => {
    if (s.src.includes('https://static.mailerlite.com/js/universal.js')) {
      s.remove()
    }
  })
  window.load_mailerlite()
}

export const wrapRootElement = ({ element }) => (
  <SearchProvider>{element}</SearchProvider>
)
