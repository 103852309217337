module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-L632DW6J2P"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zanete Knits","short_name":"zaneteknits","start_url":"/","background_color":"#ffffff","theme_color":"#d7728d","display":"minimal-ui","icon":"src/images/zaneteknits-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b15c01c16ec44b21b924770316e26010"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en","lv"],"defaultLanguage":"en","redirect":true,"redirectDefaultLanguageToRoot":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.2.1","publicApiKey":"NjdhNjBiODItZDI2NS00ZjViLTlhZmUtMDk5ODEzYWMxMTI1NjM3NjA5MTk1MjAyNDk5NzMz","defaultLang":"en","currency":"usd","openCartOnAdd":false,"useSideCart":false,"locales":{"en":{"payment":{"terms1":"By placing order you confirm that you have read, understood and agree to","terms2":"Terms and Conditions","terms3":"and","terms4":"Privacy Policy","terms5":"."}},"lv":{"payment":{"terms1":"Nospiežot pogu „Veikt pasūtījumu” apstiprinu, ka esmu iepazinies ar lapas","terms2":"“Lietošanas noteikumiem”","terms3":"un","terms4":"”Privātuma politiku”","terms5":"un piekrītu tiem."}}},"innerHTML":"\n            <payment section=\"top\">\n                <div>\n                <p className=\"terms-checkout\" style=\"line-height: 1.5;\">\n                <span style=\"display:inline\"\n                >{{ $localize('payment.terms1') }}</span><a style=\"display:inline; color: var(--primary); text-decoration: underline;\"\n                  href=\"/terms-and-conditions\"\n                  target=\"_blank\"\n                  rel=\"noopener noreferrer\"\n                > {{ $localize('payment.terms2') }}</a>\n                <span style=\"display:inline\"> {{ $localize('payment.terms3') }}</span><a style=\"display:inline; color: var(--primary); text-decoration: underline;\"\n                  href=\"/privacy-policy\"\n                  target=\"_blank\"\n                  rel=\"noopener noreferrer\"\n                > {{ $localize('payment.terms4') }}</a> <span style=\"display:inline\"> {{ $localize('payment.terms5') }}</span>\n              </p>\n                </div>\n            </payment>"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
