import * as React from "react"


export const SearchStateContext = React.createContext({
    search: {},
    setSearch: () => { },
})

export const SearchProvider = ({ children }) => {
    const [search, setSearch] = React.useState({
    })
    const [showFilters, setShowFilters] = React.useState({
        main: false,
        category: false,
        difficulty: false,
        gauge: false,
        yarn_weight: false,
    })
    return (
        <SearchStateContext.Provider value={{
            search, setSearch,
            showFilters, setShowFilters
        }}>
            {children}
        </SearchStateContext.Provider>
    )
}
